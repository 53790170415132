/* src/pages/employer/dashboard/Dashboard.css */

.dashboard-layout {
    min-height: 100vh;
  }
  
  .logo {
    height: 64px;
    margin: 16px;
    background: rgba(255, 255, 255, 0.2);
  }
  
  .header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 24px;
    background: #001529;
    color: #fff;
  }
  
  .notification-icon {
    font-size: 24px;
    cursor: pointer;
  }
  
  .dashboard-card {
    text-align: center;
  }
  
  .hospital-description {
    margin-top: 24px;
    padding: 24px;
    background: #f0f2f5;
    border-radius: 8px;
  }
  
  .hospital-description h3 {
    margin-bottom: 16px;
  }
  