.auth-container {
  display: flex;
  height: 100vh;
  background: #F4FBF8;

}

.auth-background {
  flex: 1;
  background: url('../../assets/images/bg/auth.png') no-repeat left center;
  background-size: cover;
}

.auth-container .auth-form-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 50px;
  background: #F4FBF8;
}

.auth-container .auth-form-container h2 {
  margin-bottom: 20px;
  color: #333;
}

.auth-container .auth-form-button {
  width: 100%;
}

.auth-container .auth-form-forgot {
  float: right;
}

.auth-container .ant-input,
.auth-container .ant-input-password {
  font-size: 16px;
  height: 60px;
  border-radius: 30px;
  border: 2px #006971 solid;
  align-items: center;
}
.auth-container .ant-form-item{
  margin-bottom: 20px !important;
}
.auth-container .ant-form-item-control-input-content input,.ant-form-item-explain-error{
  padding-left: 30px;

}
.auth-container .ant-input-affix-wrapper input{
  height: 100%;
  padding-left: 20px !important;
}
.auth-container .ant-form-item-control-input-content button{
background: #006971;
border-radius: 100px;
font-size: 18px;
height: 60px;
}