/* src/pages/job-management/JobManagement.css */

.job-management-layout {
    min-height: 100vh;
  }
  

  .job-management-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
  }
  
  .job-search-input {
    width: 300px;
    margin-right: 16px;
  }
  