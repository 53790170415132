/* src/pages/NotFound.css */

.not-found-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    text-align: center;
    background-color: #f8f9fa;
    color: #333;
  }
  
  .not-found-container h1 {
    font-size: 10rem;
    margin: 0;
  }
  
  .not-found-container h2 {
    font-size: 2rem;
    margin: 0;
  }
  
  .not-found-container p {
    font-size: 1.2rem;
    margin: 20px 0;
  }
  
  .not-found-link {
    display: inline-block;
    padding: 10px 20px;
    margin-top: 20px;
    background-color: #007bff;
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
  }
  
  .not-found-link:hover {
    background-color: #0056b3;
  }
  