/* src/pages/employer/dashboard/PostJob.css */

.post-job-container {
    padding: 24px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  }
  
  .post-job-container h2 {
    margin-bottom: 16px;
  }
  
  .post-job-container p {
    margin-bottom: 24px;
  }
  
  .section {
    margin-bottom: 24px;
  }
  
  .section h3 {
    margin-bottom: 16px;
  }
  
  .submit-button {
    width: 100%;
  }

  .job-preview-container {
    padding: 24px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  }
  
  .job-preview-container h2 {
    margin-bottom: 16px;
  }
  
  .job-preview-content {
    margin-bottom: 24px;
    padding: 16px;
    background: #f6f8fa;
    border-radius: 8px;
  }
  
  .job-preview-item {
    margin-bottom: 8px;
  }
  
  .submit-button {
    width: 100%;
  }
  
  .post-job-container {
    padding: 24px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  }
  
  .section {
    margin-bottom: 24px;
  }
  
  .address-card {
    cursor: pointer;
    transition: transform 0.2s;
  }
  
  .address-card:hover {
    transform: scale(1.05);
  }
  
  .address-card.selected {
    border: 2px solid #1890ff;

  }
  
  .submit-button {
    width: 100%;
  }
  .ant-descriptions-row{
   height: 10px !important;
  }